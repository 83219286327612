<template>
  <DefaultTemplate :showStep="formFlag">
    <div class="depositFunds-box">
      <div class="form-box" v-if="formFlag">
        <el-form label-position="top" :model="indiaForm" ref="indiaForm" status-icon :rules="indiaRules">
          <div class="box_top">
            <div class="box_top_left box">
              <p>{{ $t('common.withdrawChannel.indiabanktransfer') }}</p>
              <DepositInfo :instData="instData" :showNote="showNote"></DepositInfo>
              <strong>{{ $t('deposit.default.deposit') }}</strong>
              <div class="info">
                <p>
                  {{ $t('deposit.intSwift.desc', { platform: $config.info.fullName }) }}
                </p>
                <p>
                  {{ $t('deposit.intSwift.desc3', { platform: $config.info.fullName }) }}
                </p>
              </div>
              <!-- BANK DETAILS -->
              <div class="bank_info">
                <ul v-for="item in bankInfos" :key="item.title">
                  <li>
                    <span class="title_name">{{ $t(item.title) }}</span>
                    <span class="value_info">{{ item.info }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="box_top_right">
              <div class="logo circle bank"></div>
            </div>
          </div>
          <div class="box_bottom box">
            <div class="warn_info">
              <p>{{ $t('deposit.intSwift.refInfo2') }}</p>
              <p>
                {{ $t('deposit.intSwift.refInfo', { platform: $config.info.fullName }) }}
              </p>
            </div>
            <strong>{{ $t('deposit.default.sendReceipt') }}</strong>
            <div class="info">
              <p>{{ $t('deposit.indiaBankTransfer.desc1') }}</p>
            </div>
            <div class="form-list">
              <ul class="clearfix">
                <li>
                  <AccountNumber
                    :supportedCurrenciesList="validCurrencies"
                    @setCurrency="setCurrency"
                    @setAccountNumber="setAccountNumber"
                  ></AccountNumber>
                </li>
                <li>
                  <el-form-item :label="setAmtLabel(indiaForm.currency, indiaForm.amount)" prop="amount">
                    <numeric-input
                      v-model="indiaForm.amount"
                      :currency="indiaForm.currency"
                      :precision="2"
                    ></numeric-input>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.notes')">
                    <el-input v-model="indiaForm.notes" data-testid="notes"></el-input>
                  </el-form-item>
                </li>
                <li class="data">
                  <p>
                    {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'INR' }) }}
                    <span> {{ rate }}</span>
                  </p>
                  <p>
                    INR: <span>{{ getRate(rateChange) }}</span>
                  </p>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item :label="$t('common.field.upload')" prop="uploadFile">
                    <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                  </el-form-item>
                </li>
              </ul>
              <ul class="clearfix">
                <li>
                  <el-form-item class="button">
                    <el-button
                      class="btn-blue"
                      :loading="loading"
                      :disabled="loading"
                      @click="submitForm()"
                      data-testid="submit"
                    >
                      {{ $t('common.button.submit') }}
                    </el-button>
                  </el-form-item>
                </li>
              </ul>
            </div>
          </div>
        </el-form>
      </div>
      <Result v-if="successFlag">{{ $t('deposit.default.successMsg') }}</Result>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import India from '@/bankInfo/India';
import rounding from '@/util/rounding';

import mixin from '@/mixins/page/deposit';
import { apiQueryRate_USD_INR, apiIndiaP2PSubmitReceipt } from '@/resource';

export default {
  name: 'IndiaBankTransfer',
  components: { vUpload, AccountNumber, NumericInput, Result, DefaultTemplate, DepositInfo },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) < this.minLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', {
              minLimit: Math.ceil(this.minLimit),
              currency: this.accountCurrency
            })
          )
        );
      } else if (this.accountCurrency !== 'USC' && parseFloat(value) > this.maxLimit) {
        callback(new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: 'USD' })));
      } else if (this.accountCurrency === 'USC' && parseFloat(value) < this.minLimitByUSC) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLarger', { minLimit: this.minLimitByUSC, currency: this.accountCurrency })
          )
        );
      } else if (this.accountCurrency === 'USC' && parseFloat(value) > this.getMaxUSCByRate(this.maxLimit, this.rate)) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimitByUSC, currency: 'USC' }))
        );
      } else {
        callback();
      }
    };

    const checkUploadFile = (rule, value, callback) => {
      if (!(this.fileList.length > 0)) {
        callback(new Error(this.$t('common.formValidation.fileReceipt')));
      }
      callback();
    };
    return {
      indiaForm: {
        account: '',
        accountNumberOptions: [],
        amount: '',
        accountNumber: '',
        currency: '',
        notes: ''
      },
      indiaRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ],
        uploadFile: [
          {
            validator: checkUploadFile,
            required: true,
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      rate: 0.0,
      formFlag: true,
      successFlag: false,
      validCurrencies: ['USD', 'USC'],
      instData: [this.$t('deposit.reminder.inst17'), this.$t('deposit.reminder.inst18')],
      showNote: false
    };
  },
  computed: {
    bankInfos() {
      return India.bank_infos();
    },
    rateChange() {
      return rounding(Math.ceil, this.rate * this.indiaForm.amount, 2);
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.indiaForm.accountNumber = accountNumber;
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs['indiaForm'].validateField('uploadFile');
    },
    submitForm() {
      this.$refs['indiaForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.errorMessage(this.$t('deposit.default.failed'));
                // re-calling anti-reuse token
                this.fetchToken()
                  .then(resp => {
                    this.loading = false;
                  })
                  .catch(resp => {
                    this.loading = false;
                    this.errorMessage(this.$t('resetPassword.failed'));
                  });
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiIndiaP2PSubmitReceipt(
        {
          mt4Account: this.indiaForm.accountNumber,
          operateAmount: this.indiaForm.amount,
          depositAmount: this.getRate(this.rateChange),
          rate: this.rate,
          applicationNotes: this.indiaForm.notes,
          currency: this.indiaForm.currency,
          fileList: this.fileList
        },
        this.token
      );
    },
    queryRate() {
      apiQueryRate_USD_INR()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data.toLocaleString(undefined, { minimumFractionDigits: 3 });
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'INR' })
          );
        });
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/internationalAndAu.scss';
</style>
